export const AWS_CONFIG = {
    Auth: {
        region: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_MyiFsDcrg',
        userPoolWebClientId: '4sp9n3e9qq79ls0655hg6251pb',
    }
};

export const CONFIG = {
    baseApiUrl: 'https://api.kyuu.app/prod',
    apiStage: 'prod',
    stateUndoPeriod: 60000
};

export const DEFAULT_COUNTRY_CODE = 'AU';

export const AVAILABLE_COUNTRY_CODES = ['LK', 'AU']